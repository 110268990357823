import React from "react"
import {
  Container,
  Title,
  Description,
  DescriptionBox,
  ImageContainter,
  SvgHolder,
} from "../../../ToolsStyles/ShortDescriptionStyled"

import Desc1 from "../../../../../icons/tools/TIFFto/Benefits1.svg"
import Desc2 from "../../../../../icons/tools/lp/merge/DescIcon3.svg"
import Desc3 from "../../../../../icons/tools/TIFFto/QuickandEasy.svg"
import Desc4 from "../../../../../icons/tools/lp/merge/DescIcon4.svg"
import Desc5 from "../../../../../icons/tools/toJPG/ToJPGIcon1.svg"
import Desc6 from "../../../../../icons/tools/lp/merge/DescIcon6.svg"

const ShortDescriptions = () => {
  return (
    <Container>
      <DescriptionBox>
        <SvgHolder>
          <Desc1 />
        </SvgHolder>
        <Title>No Installation or Sign-up required</Title>
        <Description>
          No downloading software, no signing up necessary. Just visit this
          webpage from your browser, and get started!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc2 />
        </SvgHolder>
        <Title>Safe & Secure — Your Privacy Matters to Us</Title>
        <Description>Uploaded files are deleted after 24 hours.</Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc3 />
        </SvgHolder>
        <Title>Why Convert TIFF to PDF?</Title>
        <Description>
          Convert TIFF to PDF to make it easy to share, reduce the file size, or
          create a photo album.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc4 />
        </SvgHolder>
        <Title>100% Free</Title>
        <Description>
          Our TIFF to PDF Converter is totally free! There are no limits on the
          number of TIFF files you can convert to PDF.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          {" "}
          <Desc5 />
        </SvgHolder>
        <Title>Easy to Use</Title>
        <Description>
          Instantly convert TIFF files to PDF. Simply upload your image, press
          Convert, and download your new PDF!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          {" "}
          <Desc6 />
        </SvgHolder>
        <Title>Works on Any Device</Title>
        <Description>
          Instantly convert TIFF to PDF right in your browser, on any device!
          Windows, Mac, Chrome, Linux — you name it!
        </Description>
      </DescriptionBox>
    </Container>
  )
}

export default ShortDescriptions
