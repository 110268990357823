import React from "react"
import {
  ContainerDesk,
  ContainerMobile,
  TitleHolder,
  TitleMain,
  TitleSub,
} from "../../../ToolsStyles/HowItWorksStyeled"

function HowItWorksTitle() {
  return (
    <div>
      <ContainerDesk>
        <TitleHolder>
          <TitleSub>How it Works</TitleSub>
          <TitleMain>How to Convert TIFF to PDF Online</TitleMain>
        </TitleHolder>
      </ContainerDesk>
      <ContainerMobile>
        <TitleHolder>
          <TitleSub>How it Works</TitleSub>
          <TitleMain>How to Convert TIFF to PDF Online</TitleMain>
        </TitleHolder>
      </ContainerMobile>
    </div>
  )
}

export default HowItWorksTitle
